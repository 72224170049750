import { template as template_0b823d1d418b447ca9a418a1ee05bb26 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_0b823d1d418b447ca9a418a1ee05bb26(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
