import { template as template_39463956ae5d4145abc762726bc8a3d7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_39463956ae5d4145abc762726bc8a3d7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
